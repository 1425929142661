import oauthSettings from './vsko-oauth-configuration-stripped.json';

export const settings = {
  angularModuleName: "newsletterpreview-ui",
  projectName: "newsletterpreview-ui",
  demo: false,
  newsletterSecurity: {
    ability: "execute",
    componentHref: "/security/components/newsletter-api",
    resource: "/newslettersender/sendwithjob",
  },
  release: "nieuwsbrief-2__undefined__2024-06-27T09:54:12.548Z",
  projectDescription: "Newsletter preview of Katholiek Onderwijs Vlaanderen.",
  googleAnalytics: {
    enabled: false,
    token: "UA-12345678-90",
  },
  apisAndUrls: {
    cachedApi: "https://cached-api.katholiekonderwijs.vlaanderen",
    api: "https://api.katholiekonderwijs.vlaanderen",
    contentApi: "https://api.katholiekonderwijs.vlaanderen",
    newsletterApi: "https://api.katholiekonderwijs.vlaanderen",
    newsletter: "https://newsletter-preview.katholiekonderwijs.vlaanderen/newslettersender",
    security: "https://beveiliging-nodejs.katholiekonderwijs.vlaanderen",
  },
  logging: {
    sentry: {
      enabled: false,
      url: "https://something@sentry.io/1234567",
    },
    redux: true,
  },
  trustedOrigins: ["*"],
  oauth: oauthSettings,
};